require("@rails/ujs").start();
require("@rails/activestorage").start()
import "controllers"
import * as Futurism from '@stimulus_reflex/futurism'
import consumer from '../channels/consumer'
import LocalTime from "local-time"

LocalTime.start()
Futurism.initializeElements()
Futurism.createSubscription(consumer)
