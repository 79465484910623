import Rails from '@rails/ujs'
import { debounce } from 'lodash-es'
import ApplicationController from './application_controller'
import consumer from '../channels/consumer';
import CableReady from 'cable_ready'

let connectionStatus

export default class extends ApplicationController {
    // static targets = ['list', 'input', 'participant', 'room', 'messageattachment']

    teardown() {
        // Teardown is called from index.js controller on TurboLinks reload
    }

    initialize() {
        // Access Stimulus controller instances
        // https://docs.stimulusreflex.com/rtfm/patterns
        this.element[this.identifier] = this

        connectionStatus = true;
        document.addEventListener('stimulus-reflex:disconnected', this._disconnectedHandler);
        document.addEventListener('stimulus-reflex:connected', this._connectedHandler);

    }

    _disconnectedHandler() {
        connectionStatus = false;
    }

    _connectedHandler() {
        connectionStatus = true;
    }


    saveAttributes (event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        var data = event.target;
        var input_container = data.closest('.well');
        var input_type = this.data.get("inputType");
        // loop through all the inputs and get the values
        var values = {};
        var attribute_set_id = input_container.dataset.attributeSetId;
        if(input_type == "input_number"){
            var inputs = input_container.querySelectorAll('input[type="number"]:not([disabled])');
        } else {
            var inputs = input_container.querySelectorAll('input[type=checkbox]:not([disabled]):checked');
        }
        inputs.forEach(function(input) {
            if (input.value >= 0){
                values[input.name] = {val: input.value, name: input.dataset.attributename };
            }
        }
        );

        this.stimulate(
            'BookingReflex#saveAttributes', values, attribute_set_id).then(() => {
                $("input[type='number'].touchspin:not(:disabled)").TouchSpin({max: 100000});
            }
        );
    }
}
