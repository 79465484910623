// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'

const application = Application.start()
document.addEventListener('turbolinks:before-cache', function() {
    application.controllers.forEach(function(controller){
        if(typeof controller.teardown === 'function') {
            controller.teardown();
        }
    });
});

document.addEventListener('chat:notify', e => {
    const { title, options, clickUrl, showToast } = e.detail
    if (baseTitle !== window.document.title) {
        // if window or tab is out of focus, send browser notification as well
        if (Notification.permission === 'granted') {
            if (clickUrl) options.onclick = () => window.open(clickUrl)
            const notification = new Notification(title, options)
        }
    } else {
        // if window is in focus, check if we need to show notifications (if admin is not active in chat)
        if (showToast) {
            $("#navbar-chat-notifications-container .notification-icon").click();
        }
    }
});


const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer, controller, debug: false })
