import Rails from '@rails/ujs'
import { debounce } from 'lodash-es'
import ApplicationController from './application_controller'
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'
import Dropzone from "dropzone";

let connectionStatus
let currentRoomChannel
const reload = (controller, participant_id) => {
    controller.stimulate('ChatReflex#reload', participant_id)
}
const debouncedReload = debounce(reload, 100)

export default class extends ApplicationController {
    static targets = ['list', 'input', 'participant', 'room', 'messageattachment', 'presetmessage']
    static values = { refreshConversationlistInterval: Number }

    teardown() {
        // Teardown is called from index.js controller on TurboLinks reload
        this._unsubscribeCurrentRoom();
        if (this.refreshConversationsTimer) {
            clearInterval(this.refreshConversationsTimer);
        }
    }

    initialize() {
        // Access Stimulus controller instances
        // https://docs.stimulusreflex.com/rtfm/patterns
        this.element[this.identifier] = this

        // We need to know when there are new messages that have been created by the other party
        // so we subscribe to the selected conversations's generated room ID
        // When no conversations are selected, there's no room, so we skip subscribing to room
        if (this.roomTarget.value) {
            this._subscribeToRoomChannel(this.roomTarget.value)
        }
        this.scroll(1)

        connectionStatus = true;

        document.addEventListener('stimulus-reflex:disconnected', this._disconnectedHandler);
        document.addEventListener('stimulus-reflex:connected', this._connectedHandler);
        document.addEventListener('chat:mark_received_messages_as_read', e => {
            // if window is in focus and we're chatting with the same recipient who sent us a message, mark messages from them as read
            if ((e.detail.fromParticipant === this.participantTarget.value) || (baseTitle == window.document.title)) {
                this.stimulate(
                    'ChatReflex#mark_messages_from_sender_as_read',
                    e.detail.fromParticipant
                )
            }
        });

        if (this.data.get("refreshConversationlistIntervalValue")) {
            this._startRefreshingConversationList();
        }
    }

    _startRefreshingConversationList() {
        // console.log("Will start refreshing user list");
        this.refreshConversationsTimer = setInterval(() => {
            this._refreshConversationList()
        }, this.data.get("refreshConversationlistIntervalValue"))
    }

    _refreshConversationList() {
        this.stimulate('ChatReflex#reloadConversationsList')
    }

    _disconnectedHandler() {
        connectionStatus = false;
        setTimeout(() => {
            // delay disconnected notification to prevent it showing up on page reloads etc
            if (!connectionStatus) {
                jQuery("#chat-disconnect-notification").removeClass("notification-hidden");
                jQuery("#chatSubmitBtn").addClass("disabled");
            }
        }, 3000);
    }

    _connectedHandler() {
        connectionStatus = true;
        jQuery("#chat-disconnect-notification").addClass("notification-hidden");
        jQuery("#chatSubmitBtn").removeClass("disabled");
    }

    _unsubscribeCurrentRoom() {
        if (currentRoomChannel && currentRoomChannel.unsubscribe) currentRoomChannel.unsubscribe();
    }

    _subscribeToRoomChannel(room) {
        this._unsubscribeCurrentRoom();
        currentRoomChannel = consumer.subscriptions.create({ channel: "ChatChannel", room: 'room_' + room }, {
            received(data) {
                if (data.cableReady) CableReady.perform(data.operations)
            }
        });
    }

    post(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        // also don't post anything if there's no message or no room
        if (!this.inputTarget.value || !this.roomTarget.value) {
            this.inputTarget.focus()
            return
        }

        this.stimulate(
            'ChatReflex#post',
            this.participantTarget.value,
            this.inputTarget.value,
            this.messageattachmentTarget.value
        )
        this.inputTarget.value = ''
        this.inputTarget.focus()
        $('#chatSubmitBtn').prop("disabled", true);
        $('#chatSubmitBtn').text('Sending...');
        setTimeout(() => {
            // delay disconnected notification to prevent it showing up on page reloads etc
            $('#chatSubmitBtn').prop("disabled", false);
            $('#chatSubmitBtn').text('Send');
        }, 3000);
        this.stimulate('ChatReflex#setMessageAttachment')
        this.scroll(1)
    }

    attachAppServicesLink(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        this.stimulate(
            'ChatReflex#attachAppServicesLink'
        )
    }

    afterAttachAppServicesLink() {
        $('#full-modal').modal('show');
    }

    attachWebsiteLink(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        this.stimulate(
            'ChatReflex#attachWebsiteLink',
        )
    }

    afterAttachWebsiteLink() {
        $('#full-modal').modal('show');
    }

    usePresetMessage(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        this.stimulate(
            'ChatReflex#usePresetMessage',
        )
    }

    afterUsePresetMessage() {
        $('#full-modal').modal('show');

        $('#TaskForm #app-content-list input').on('change', function (e) {
            $('#TaskForm #additional_comments').show();
            $('#TaskForm #TaskSubmit').show();
        });

        $('#TaskForm #show_attachments_link').on('click', function (e) {
            e.preventDefault();
            $('#TaskForm #show_attachments_link').hide();
            $('#TaskForm #attachments').show();
        });

        $('#TaskForm #SubmitTask').on('click', function (e) {
            document.getElementById('chatController').chat.performFillPresetMessage($('#participant-to-send-to').val(), serialize_form_to_object($('#TaskForm')));
            $('#full-modal').modal('hide');
        });
    }


    performFillPresetMessage(participant, e) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        const preset_message = e

        if (!preset_message) return;
        this.stimulate(
            'ChatReflex#setPresetMessage',
            participant,
            preset_message
        )
    }

    attachImage(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        this.stimulate(
            'ChatReflex#attachImage',
        )
    }

    afterAttachImage() {
        $('#full-modal').modal('show');
        $("#feature_image").parent().trigger("click");
    }

    afterSetMessageAttachmentImage() {
        $('#full-modal').modal('hide');
        $('#ImageSubmit').prop("disabled", false);
        $('#ImageSubmit').text('Add image');
    }

    attachCameraImage(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;
        navigator.permissions.query({ name: 'camera' }).then((permissionObj) => {
            if (permissionObj.state == "granted" || permissionObj.state == "prompt") {
                this.stimulate(
                    'ChatReflex#attachCameraImage',
                )
            } else {
                this.stimulate(
                    'ChatReflex#attachImage',
                )
            }
        })
            .catch((error) => {
                $("#errorMessage").text('Got error :', error)
            })

    }

    afterAttachCameraImage() {
        $('#full-modal').modal('show');
        $("#feature_image").parent().trigger("click");
    }

    attachAppServiceCategoryLink(event) {
        Rails.stopEverything(event)

        // if the user if offline, we'll disable sending to prevent messages going out into the void
        if (!connectionStatus) return;

        this.stimulate(
            'ChatReflex#attachAppServiceCategoryLink'
        )
    }

    afterAttachAppServiceCategoryLink() {
        $('#full-modal').modal('show');
    }


    performAttachAppLink(e) {
        var message_attachment = e
        if (!message_attachment) return;

        this.stimulate('ChatReflex#setMessageAttachment',
            message_attachment
        )
        $('#full-modal').modal('hide');
    }

    performAttachImage(e) {
        const message_attachment = e

        if (!message_attachment) return;

        this.stimulate('ChatReflex#setMessageAttachmentImage',
            message_attachment
        )
        $('#ImageSubmit').prop("disabled", true);
        $('#ImageSubmit').text('Adding images...');
    }

    validateWebsiteLink(e) {
        var message_attachment = e
        if (typeof e == 'string') {
            message_attachment = JSON.parse(e)
        };

        if (!message_attachment) return;

        this.stimulate('ChatReflex#validateWebsiteLink',
            message_attachment
        )
        $('#full-modal').modal('hide');
    }

    clearAttachment() {
        this.stimulate('ChatReflex#setMessageAttachment',
            null
        )
    }

    afterReload() {
        this.scroll(1)
        $('[data-toggle="tooltip"]').tooltip();

    }

    select_chat_participant(event) {
        const dataElement = $(event.target).closest('div.participant-data')
        // Check IE10 support for dataset
        // jQuery .data() did not work correctly when user list was updated through a morph
        const room_id = dataElement[0].dataset['room']
        const participant_id = dataElement[0].dataset['participantId']
        // console.log("Will subscribe to room", room_id)
        this._subscribeToRoomChannel(room_id)
        this.reload(participant_id)
    }

    scroll(delay = 10) {
        const lists = document.querySelectorAll('[data-target="chat.list"]')
        setTimeout(() => {
            lists.forEach(e => (e.scrollTop = e.scrollHeight))
        }, delay)
    }

    reload(participant_id) {
        // console.log("will reload with participant...", participant_id)
        debouncedReload(this, participant_id)
    }
}
